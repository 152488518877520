





































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Particles from "@/components/Particles.vue";
import Notifier from "@/helpers/notifier";
import { namespace } from "vuex-class";
import FinancialInstitution from "@/models/financialInstitution";
import agent from "@/helpers/agent";
import { AppUser } from "@/models/user";
const app = namespace("app");
@Component({ components: { Particles } })
export default class CreateUserView extends Vue {
  @app.Action
  login: any;

  @app.Getter user!: AppUser;

  firstName = "";
  lastName = "";
  userName = "";
  phone = "";
  password = "";
  financialInstitutionID = "";
  isPwd = true;
  confirmPassword = "";
  email = "";
  passwordInputType = "Password";
  logo = `@assets/logo.png`;
  submitting = false;
  institutions: FinancialInstitution[] = [];
  role = "User";
  roles = ["SuperAdmin", "Reviewer", "Manager", "User"];

  onSubmit() {
    // implement register
    this.submitting = true;
    agent.Users.register({
      firstName: this.firstName,
      lastName: this.lastName,
      phoneNumber: this.phone,
      financialInstitutionID: this.financialInstitutionID,
      username: this.email,
      email: this.email,
      password: this.password,
      role: this.role,
      fullName: "",
    })
      .then(() => {
        Notifier.showPositive(`You have succesfully registered`);
        this.$router.push("/list-user");
      })
      .catch(() => Notifier.showError("Error occured during registration"))
      .finally(() => (this.submitting = false));
  }
  isValidEmail(val: string) {
    const emailPattern =
      /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    return emailPattern.test(val) || "Invalid email";
  }
  onReset() {
    this.firstName = "";
    this.email = "";
    this.password = "";
    this.confirmPassword = "";
  }
  get readonly() {
    return this.user.role !== "SuperAdmin";
  }
  async created() {
    const roles = await agent.Roles.list();
    this.roles = roles.rows.map((s) => s.name);

    agent.FinancialInstitutions.list("", true, 1, -1, "Name").then((list) => [
      (this.institutions = list.rows),
      (this.financialInstitutionID = this.user.financialInstitutionID),
    ]);
  }
}
